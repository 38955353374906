<template>
    <div class="myClass">
        <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane label="个人信息">
                <div class="userInfo">
                    <div class="header-bg">
                        <fileUpload
                            @fileCallback="fileComs"
                            :options="{ fileType: 'headPic', imgUrl: headPath }">
                        </fileUpload>
                    </div>
                    <div class="usre-form">
                        <el-form ref="form" :model="form" label-width="80px">
                            <el-form-item label="昵称">
                                <el-input v-model="form.nickName" placeholder="请输入昵称"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="性别">
                                <el-radio-group v-model="form.sex">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item> -->
                            <!-- <el-form-item label="真实姓名">
                                <el-input v-model="form.actualName" placeholder="请输入真实姓名"></el-input>
                            </el-form-item> -->
                            <el-form-item label="手机号码">
                                <el-input v-model="form.mobile" disabled placeholder="请输入手机号码"></el-input>
                                <!-- <span @click="genghuanshoujihao()">更换手机号</span> -->
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">保存</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="修改密码">
                <div class="editPswd">
                    <el-form ref="pswdForm" :model="pswdForm" label-width="100px" :rules="pswdRules">
                        <!-- <el-form-item label="手机号" prop="amendMobile">
                            <el-input v-model="pswdForm.amendMobile" placeholder="请输入手机号"></el-input>
                        </el-form-item> -->
                        <el-form-item label="验证码" prop="codeValue" class="editPswd_code">
                            <el-input placeholder="请输入验证码" prefix-icon="el-icon-unlock" v-model="pswdForm.codeValue" class="item_input" maxlength=4></el-input>
                            <el-button type="primary" @click="getCode()" class="item_button" :disabled="countdown!='获取验证码'?true:false">{{countdown}}</el-button>
                        </el-form-item>
                        <el-form-item label="新密码" prop="newPassword">
                            <el-input v-model="pswdForm.newPassword" placeholder="请输入新密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onPswd">确认</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="收货地址管理">
                <div class="siteBox">
                    <div class="siteBox_header">
                        <el-button type="primary" @click="addAdress_popup()">新增收货地址</el-button>
                        <!-- <div class="siteBox_header_explain">
                            <span>您已经创建</span>
                            <span>{{}}</span>
                            <span>个收货地址，最多可创建</span>
                            <span>25</span>
                            <span>个</span>
                        </div> -->
                    </div>
                    <div class="siteBox_list">
                        <div class="siteBox_list_item" v-for="(item,index) in addressList" :key="index">
                            <div class="siteBox_list_item_name">
                                <span>收货人：</span>
                                <span>{{item.name}}</span>
                            </div>
                            <div class="siteBox_list_item_address">
                                <span>地址：</span>
                                <span>{{item.province}}{{item.city}}{{item.area}}{{item.address}}</span>
                            </div>
                            <div class="siteBox_list_item_mobile">
                                <span>手机：</span>
                                <span>{{item.mobile}}</span>
                            </div>
                            <div class="siteBox_list_item_isDefault">
                                <!-- <el-checkbox v-model="item.isDefault" disabled>设置默认</el-checkbox> -->
                                
                            </div>
                            <div class="siteBox_list_item_position" style="top:10px;color:red;"><span v-if="item.isDefault">默认</span></div>
                            <div class="siteBox_list_item_position" style="cursor:pointer;" @click="redact(item)">编辑</div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="更换绑定手机号">
                <div class="changeTel">
                    <div class="changeTel_steps">
                        <div v-for="(item,index) in stepList" :key="index" class="changeTel_steps_item">
                            <div class="changeTel_steps_item_left">
                                <img :src="changeTelIndex>=item.id?item.icon_block:item.icon" alt="">
                            </div>
                            <div class="changeTel_steps_item_right">
                                <p>
                                    <span>{{item.title}}</span>
                                    <span v-if="item.id!=3" :class="changeTelIndex>=item.id?'active':''"></span>
                                </p>
                                <p>{{item.description}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="changeTel_operate"> 
                        <el-form ref="changeTel" :model="changeTel" label-width="120px" :rules="changeTelRules" v-if="changeTelIndex!=3">
                            <el-form-item label="当前手机号" v-if="changeTelIndex==1">
                                <div class="changeTel_operate_current">
                                    <span>{{this.userInfo.mobile}}</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="手机验证码" prop="codeValue" class="editPswd_code" v-if="changeTelIndex==1">
                                <el-input placeholder="请输入验证码" prefix-icon="el-icon-unlock" v-model="changeTel.changeTelCode" class="item_input" maxlength=4></el-input>
                                <el-button type="primary" @click="getNewTelCode()" class="item_button" :disabled="countdown!='获取验证码'?true:false" maxlength="4">{{countdown}}</el-button>
                            </el-form-item>
                            <el-form-item label="新的手机号" prop="newTelValue" v-if="changeTelIndex==2">
                                 <div class="changeTel_operate_input">
                                    <el-input placeholder="请输入您要更换的手机号" v-model="changeTel.newTelValue" maxlength="11"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="手机验证码" prop="changeTelCode" class="editPswd_code" v-if="changeTelIndex==2">
                                <el-input placeholder="请输入验证码" prefix-icon="el-icon-unlock" v-model="changeTel.changeTelCode" class="item_input" maxlength="4"></el-input>
                                <el-button type="primary" @click="getNewTelCode()" class="item_button" :disabled="countdown!='获取验证码'?true:false">{{countdown}}</el-button>
                            </el-form-item>
                            <el-form-item>
                                <div style="display:flex;">
                                    <el-button type="primary" @click="verification" v-if="changeTelIndex==1">下一步</el-button>
                                    <el-button type="primary" @click="next" v-if="changeTelIndex==2">下一步</el-button>
                                </div>
                            </el-form-item>
                        </el-form>

                       
                        <!-- <div class="changeTel_operate_input">
                            <span>手机验证码</span>
                            <el-input placeholder="请输入手机号验证码" v-model="changeTel.changeTelCode">
                                <template slot="append">{{countdown}}</template>
                            </el-input>
                        </div>
                        <div class="changeTel_operate_btn">
                            <el-button type="primary">下一步</el-button>
                        </div> -->
                    </div>
                    <div class="changeTel_operate" v-if="changeTelIndex==3" style="height: 287px;display: flex;flex-flow: column;align-items: center;justify-content: center;position: relative;background: #E7EBFF;">
                        <div class="changeTel_operate_success">
                            <img src="@/assets/exam/testRight.png" alt="" style="width: 70px;height: 70px;margin-bottom: 30px;">  
                            <p style="display: flex;align-items: center;justify-content: flex-start;">
                                <span>您的手机号码已成功更换为：</span>
                                <span style="color:#5F7CFD;">{{this.changeTel.newTelValue}}</span>
                            </p>
                        </div>
                        <div class="changeTel_operate_back" style="position: absolute;bottom: 20px;right: 20px;border-bottom: 1px solid #5F7CFD;cursor: pointer;" @click="back">
                            <span style="padding-bottom: 5px;color:#5F7CFD;">返回个人资料</span>
                            <!-- <el-button type="text"></el-button> -->
                        </div>

                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="popup_address">
            <el-dialog title="添加地址" :visible.sync="addressDialogVisibleAdd" width="800px" center>
                <el-form ref="form" :model="addressForm" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="所在地区">
                        <el-select v-model="addressForm.province" placeholder="请选择省" @change="proviceChange" style="width:33%;">
                            <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.provinceId"></el-option>
                        </el-select>
                        <el-select v-model="addressForm.city" placeholder="请选择市" @change="cityChange" style="width:33%;">
                            <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.cityId"></el-option>
                        </el-select>
                        <el-select v-model="addressForm.area" placeholder="请选择区" @change="areaChange" style="width:34%;">
                            <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.areaId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收货人">
                        <el-input v-model="addressForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="addressForm.mobile" maxlength='11'></el-input> 
                    </el-form-item>
                    <el-form-item label="详细地址">
                        <el-input v-model="addressForm.site"></el-input>
                    </el-form-item>
                    <el-form-item label="是否默认">
                        <el-switch v-model="addressForm.delivery"></el-switch>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addAdress">添加</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
        <div class="popup_address">
            <el-dialog title="修改地址" :visible.sync="addressDialogVisibleAmend" width="800px" center>
                <el-form ref="form" :model="addressForm" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="所在地区">
                        <el-select v-model="addressForm.province" placeholder="请选择省" @change="proviceChange">
                            <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.provinceId"></el-option>
                        </el-select>
                        <el-select v-model="addressForm.city" placeholder="请选择市" @change="cityChange">
                            <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.cityId"></el-option>
                        </el-select>
                        <el-select v-model="addressForm.area" placeholder="请选择区" @change="areaChange">
                            <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.areaId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收货人">
                        <el-input v-model="addressForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="addressForm.mobile" maxlength='11'></el-input> 
                    </el-form-item>
                    <el-form-item label="详细地址">
                        <el-input v-model="addressForm.site"></el-input>
                    </el-form-item>
                    <el-form-item label="是否默认">
                        <el-switch v-model="addressForm.delivery"></el-switch>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="editAdress">修改</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { notification_list, edit_info, mine_index,update_password,binding_mobile,verification_code } from "../../api/myself"
import { address_list, address_edit,address_setDefault,address_delete,address_add,get_areas,get_citys,get_provices } from "../../api/other"
import { mobile_send } from "@/api/login";
import fileUpload from '../../components/FileUpload.vue'
export default {
    components: {
        fileUpload
    },
    data() {
        return {
            changeTelIndex:1,
            stepList:[
                {
                    id:1,
                    title:'验证',
                    description:'当前使用手机号验证',
                    icon:require('@/assets/mySelf/step1_block.png'),
                    icon_block:require('@/assets/mySelf/step1_block.png'),
                },
                {
                    id:2,
                    title:'更换新手机',
                    description:'输入新手机号并验证',
                    icon:require('@/assets/mySelf/step2.png'),
                    icon_block:require('@/assets/mySelf/step2_block.png'),
                },{
                    id:3,
                    title:'完成',
                    description:'完成手机号更换',
                    icon:require('@/assets/mySelf/step3.png'),
                    icon_block:require('@/assets/mySelf/step3_block.png'),
                }
            ],
            changeTelCode:'',// 更换手机号的验证码
            countdown:'获取验证码',
            downtime:60,
            amendMobile:'',
            addressDialogVisibleAdd:false,// 添加地址
            addressDialogVisibleAmend:false,// 添加地址
            // 添加地址的数据
            addressForm:{
                province:'',
                city:'',
                area:'',
                name:'',
                mobile:'',
                site:'',
                delivery:false,
            },
            provinceList:[],
            cityList:[],
            areaList:[],
            active: 0,
            userInfo: JSON.parse(localStorage.getItem('setuserInfo')),
            headPath: '',
            form: {
                nickName: '',
                actualName: '',
                sex: '1',
                mobile: ''
            },
            pswdForm: {
                codeValue: '',
                newPassword: '',
                amendMobile: ''
            },
            changeTelRules:{
                changeTelCode: [{
                    required: true, message: '请输入验证码', trigger: 'blur'
                }],
                newTelValue: [{
                    required: true, message: '请输入新手机号', trigger: 'blur'
                }]
            },
            changeTel:{
                changeTelCode:'',
                newTelValue:'',
            },
            pswdRules: {
                amendMobile: [{
                    required: true, message: '请输入手机号', trigger: 'blur'
                }],
                newPassword: [{
                    required: true, message: '请输入新密码', trigger: 'blur'
                }],
                codeValue: [{
                    required: true, message: '请输入验证码', trigger: 'blur'
                }],
            },
            addressList:[],
            addressObj:{},
            addressId:0,
        }
    },
    mounted() {
        this.notification_list();
        this.getUser();
        this.address_list()
        this.get_provices()

    },
    methods: {
        genghuanshoujihao(){
            this.$router.push({
                path: '/myself/personal',
                query: {
                    
                }
            })
        },
        back(){
            this.active='0'
        },
        // 获取验证码
        getCode(){
            // if(this.pswdForm.amendMobile=='' || this.pswdForm.amendMobile==null || this.pswdForm.amendMobile==undefined){
            //     this.$message.error('请输入正确的手机号码');
            // }else{
                var obj={
                    // mobile:this.pswdForm.amendMobile,
                    mobile:this.userInfo.mobile,
                    type:3
                }
                mobile_send(obj).then(res=>{
                    console.log(res)
                    if(res.data.code==0){
                        this.countDownTime()
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(error=>{
                    console.log(error)
                })
            // }
        },
        // 获取验证码
        getNewTelCode(){
            // if(this.pswdForm.amendMobile=='' || this.pswdForm.amendMobile==null || this.pswdForm.amendMobile==undefined){
            //     this.$message.error('请输入正确的手机号码');
            // }else{
                var mobile=''
                if(this.changeTelIndex==1){
                    mobile=this.userInfo.mobile
                }else{
                    mobile=this.changeTel.newTelValue
                }
                var obj={
                    // mobile:this.pswdForm.amendMobile,
                    mobile:mobile,
                    type:4
                }
                mobile_send(obj).then(res=>{
                    console.log(res)
                    if(res.data.code==0){
                        this.countDownTime()
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(error=>{
                    console.log(error)
                })
            // }
        },
        // 倒计时
        countDownTime(){
            let that = this
            let downtime = that.downtime
            setTimeout(()=>{
                if(that.downtime>0){
                    that.downtime = downtime-1
                    that.countdown = that.downtime+'秒后重试'
                    that.countDownTime()
                }
                else{
                    that.downtime = 60
                    that.countdown='获取验证码'
                }
            },1000)
        },
        // 点击添加按钮
        addAdress_popup(){
            var that=this
            that.addressDialogVisibleAdd=true
            var obj={
                province:'',
                city:'',
                area:'',
                name:'',
                mobile:'',
                site:'',
                delivery:false,
            }
            that.addressForm=obj
        },
        // 添加地址
        addAdress(){
            var isDefault=null
            if(this.addressForm.delivery==true){
                isDefault=1
            }else{
                isDefault=0
            }
            var obj={
                province:this.proviceName,
                provinceId:this.addressForm.province,
                area:this.areaName,
                areaId:this.addressForm.area,
                city:this.cityName,
                cityId:this.addressForm.city,
                address:this.addressForm.site,
                isDefault:isDefault,
                mobile:this.addressForm.mobile,
                name:this.addressForm.name,
            }
            address_add(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.addressDialogVisibleAdd=false
                    this.address_list()
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        editAdress(){
            var isDefault=null
            if(this.addressForm.delivery==true){
                isDefault=1
            }else{
                isDefault=0
            }
            var obj={
                province:this.proviceName,
                provinceId:this.addressForm.province,
                area:this.areaName,
                areaId:this.addressForm.area,
                city:this.cityName,
                cityId:this.addressForm.city,
                address:this.addressForm.site,
                isDefault:isDefault,
                mobile:this.addressForm.mobile,
                name:this.addressForm.name,
                id:this.addressId,
            }
            console.log(obj);
            address_edit(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.addressDialogVisibleAmend=false
                    this.address_list()
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 获取省
        get_provices(){
            get_provices().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.provinceList=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 选择省
        proviceChange(value){
            console.log(value)
            for(var i in this.provinceList){
                if(value==this.provinceList[i].provinceId){
                    this.proviceName=this.provinceList[i].name
                }
            }
            var obj={
                provinceId:value
            }
            this.get_citys(obj)
        },
        // 获取市
        get_citys(obj){
            get_citys(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.cityList=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 市选择
        cityChange(value){
            for(var i in this.cityList){
                if(value==this.cityList[i].cityId){
                    this.cityName=this.cityList[i].name
                }
            }
            var obj={
                cityId:value
            }
            this.get_areas(obj)
        },
        // 获取区
        get_areas(obj){
            get_areas(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.areaList=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 区选择
        areaChange(value){
            for(var i in this.areaList){
                if(value==this.areaList[i].areaId){
                    this.areaName=this.areaList[i].name
                }
            }
        },
        handleClick() { },
        notification_list() {
            notification_list().then(res => {
                let obj = {
                    "content": "test", "intime": "2020", "isRead": 0, "notificationId": 0, "type": 0
                }
                res.data.data.push(obj);
                this.dataList = res.data.data;
            })
        },
        getUser() {
            var setuserInfo = localStorage.getItem('setuserInfo');
            console.log("hello-----------------------");
            console.log(setuserInfo);
            let userInfo = JSON.parse(setuserInfo);
            
            console.log(userInfo);
            // let userInfo = JSON.parse(this.$store.state.userInfo);
            if (userInfo) {
                let form = this.form;
                form.nickName = userInfo.nickName;
                form.actualName = userInfo.actualName;
                // form.sex = String(userInfo.sex);
                form.mobile = userInfo.mobile;
                this.headPath = userInfo.headPic
            }
        },
        // 表单
        onSubmit() {
            let form = this.form;
            form.headPath = this.headPath;
            let obj = {
                actualName:form.actualName,
                headPath:this.headPath,
                nickName:form.nickName
            };
            // Object.keys(form).filter(item => {
            //     console.log(form[item]);
            //     if (form[item] != '') {
            //         obj[item] = form[item]
            //     }
            // })
            console.log(obj);
            edit_info(obj).then(res => {
                this.getUserInfo();
            })
        },
        // 获取用户信息 
        getUserInfo() {
            mine_index().then(res => {
                console.log("mine_index");
                console.log(res);
                this.$store.commit("setuserInfo", { userInfo: res.data.data });
                localStorage.setItem("setuserInfo", JSON.stringify(res.data.data))
                // this.getUser();
                location.reload();
            })
        },
        // 校验手机号
        verification(){
            var obj={
                mobile:this.userInfo.mobile,
                smsCode:this.changeTel.changeTelCode,
            }
            verification_code(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.downtime=0
                    this.countdown='获取验证码'
                    this.$message.success('检验通过')
                    var obj = {
                        changeTelCode: '',
                        newTelValue: ''
                    };
                    this.changeTel= obj;
                    this.changeTelIndex++
                }else{
                    this.$message.error('检验失败')
                }
                
            }).catch(error=>{
                console.log(error)
            })
        },
        // 更改手机号下一步
        next(){
            var obj={
                mobile:this.changeTel.newTelValue,
                smsCode:this.changeTel.changeTelCode,
            }
            binding_mobile(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.downtime=0
                    this.countdown='获取验证码'
                    this.$message.success('修改成功')
                    // var obj = this.changeTel
                    // obj.changeTelCode=''
                    // this.changeTel= obj;
                    // this.changeTelIndex++
                    // var userInfo=this.userInfo
                    // userInfo.mobile=this.changeTel.newTelValue
                    // this.userInfo=userInfo
                    // localStorage.setItem('setuserInfo',this.userInfo);
                    window.localStorage.clear();
                    this.$router.push('/')
                }else{
                    this.$message.error('修改失败')
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 密码
        onPswd() {
            var obj={
                mobile:this.userInfo.mobile,
                newPassword:this.pswdForm.newPassword,
                smsCode:this.pswdForm.codeValue
            }
            update_password(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.$message.success('修改成功')
                    this.countdown='获取验证码'
                    var obj = {
                        codeValue: '',
                        newPassword: ''
                    };
                    this.pswdForm= obj;
                    
                    // 退出
                    // this.$store.removeItem("setuserInfo")
                    // localStorage.removeItem("token");
                    // this.islogin = false
                    // this.$router.push({
                    //     path: '/',
                    //     query: {}
                    // });
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 图片上传
        fileComs(res) {
            console.log(res);
            this.comsFileList = [];
            const files = res;
            files.forEach((item) => {
                this.comsFileList.push({
                    type: 0,
                    name: item.name,
                    url: item.url,
                });
            });
            console.log(this.comsFileList);
            this.headPath = res[0].url;
        },
        // 编辑
        redact(item){
            console.log(item);
            this.addressDialogVisibleAmend=true
            this.addressForm.name=item.name
            this.addressForm.mobile=item.mobile
            this.addressForm.province=item.provinceId
            if(item.provinceId!='' && item.provinceId!=null && item.provinceId!=undefined){
                var obj={
                    provinceId:item.provinceId
                }
                this.get_citys(obj)
            }
            this.addressForm.city=item.cityId
            if(item.cityId!='' && item.cityId!=null && item.cityId!=undefined){
                var obj={
                    cityId:item.cityId
                }
                this.get_areas(obj)
            }
            this.addressForm.area=item.areaId
            // if(item.isdel==0){
            //     this.addressForm.delivery=false
            // }else{
            //     this.addressForm.delivery=true
            // }
            this.addressForm.delivery = item.isDefault;
            this.addressForm.site=item.address
            this.addressId = item.id;
            this.proviceName = item.province
            this.cityName = item.city;
            this.areaName = item.area;
        },
        // 地址列表
        address_list(){
            address_list().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.addressList=res.data.data
                    for(var i in this.addressList){
                        if(this.addressList[i].isDefault==0){
                            this.addressList[i].isDefault=false
                        }else{
                            this.addressList[i].isDefault=true
                        }
                    }
                }else{
                    this.$message.error(error)
                }
            }).catch(error=>{
                console.log(error)
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.userInfo {
    .header-bg {
        border-bottom: 1px solid #dddddd;
        background: #e7ebff;
        padding: 30px 0;
    }

    .usre-form {
        text-align: left;
        margin-top: 30px;
        width: 60%;
    }
}
.editPswd {
    text-align: left;
    width: 60%;
}
</style>